import axios from "../http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-商品管理-关键词搜索-用户搜索词列表
 * @param {*} params
 * @returns
 */
export function postMemberSearchList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/member/search/list`,
    data: params,
  });
}
/**
 * 总控-商品管理-关键词搜索-绑定关键词
 * @param {*} params
 * @returns
 */
export function postMemberSearchBind(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/member/search/bind`,
    data: params,
  });
}

/**
 * 总控-商品管理-关键词搜索-导出
 * @param {*} params
 * @returns
 */
export function postMemberSearchExport(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/member/search/export`,
    responseType: "blob",
    params,
  });
}

/**
 * 总控-商品管理-关键词汇总
 * @param {*} params
 * @returns
 */
export function postMemberSearchTotalList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/member/search/total/list`,
    data: params,
  });
}

/**
 * 总控-商品管理-热词搜索-导出
 * @param {*} params
 * @returns
 */
export function postMemberSearchTotalExport(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/member/search/total/export`,
    responseType: "blob",
    params,
  });
}
